
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/softbuilt",
      function () {
        return require("private-next-pages/softbuilt/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/softbuilt"])
      });
    }
  